import { Link, PageProps } from "gatsby"
import React, { useState, useContext, useEffect } from "react"
import { SearchResultProps } from "../components/Search/Search.d"
import tw from "twin.macro"
const {
  getStringValuesArray,
  mapValuesToObject,
} = require("../helpers")
import { LanguageContext } from "../context/Language"
import TranslateClient from "../clients/TranslateClient"
import PaginatedResults from "../components/Search/PaginatedResults"

const Search: React.FC<PageProps> = ({ location, navigate }) => {
  const locationState = location?.state as any
  const results: SearchResultProps[] = locationState?.results
  const query: string = locationState?.query

  const searchResultsText = "Search Results"
  const backText = "Back"
  const [text, setText] = useState({ searchResultsText, backText })
  const { language } = useContext(LanguageContext)
  
  

  useEffect(() => {
    if (language === "en") {
      setText({ searchResultsText, backText })
    } else {
      ;(async () => {
        const textToTranslate = getStringValuesArray([
          searchResultsText,
          backText,
        ])
        const translations = await TranslateClient.translate(
          textToTranslate,
          language
        )
        const translated = mapValuesToObject(text, translations)
        setText(translated)
      })()
    }
  }, [language])

  return (
    <section
      css={[
        tw`w-full pt-20`,
        {
          backgroundImage: "url('/airport-interior.png')",
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain"
        },
      ]}
    >
      <article css={[tw`px-8 md:px-20 max-w-8xl mx-auto`]}>
        <Link
          css={[tw`font-extrabold text-blue-250 hover:text-blue-300`]}
          to={locationState?.prevPath}
        >
          &lt; {text?.backText}
        </Link>
        <h1 css={[tw`font-extrabold text-2xl md:text-3xl mt-12`]}>
          {text?.searchResultsText}
        </h1>
        {results && (
          <>
            <p css={[tw`text-xs py-6`]}>
              {`${results.length} ${
                results.length === 1 ? "result" : "results"
              }${query ? " for '" + query + "'" : ""}`.toUpperCase()}
            </p>
            <PaginatedResults results={results} resultsPerPage={10} />
          </>
        )}
        
      </article>
    </section>
  )
}

export default Search
