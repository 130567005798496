import React from 'react';
import tw from 'twin.macro';

interface Props {
  total: number;
  itemsPerPage: number
  currentPage: number
  onPrev: () => void
  onNext: () => void
  onGotoPage: (page: number) => void
}

const Pagination: React.FC<Props> = ({total, itemsPerPage, currentPage, onNext, onPrev, onGotoPage}) => {

  const pages = Array(Math.ceil(total/itemsPerPage)).fill(0).map((_, index) => index + 1)

  if(pages.length < 2) return null

  return (
  <div css={[tw`py-3 flex items-center justify-between`]}>

  <div css={[tw`flex-1 flex items-center justify-end`]}>
      <nav css={[tw`relative z-0 inline-flex rounded-md shadow-sm -space-x-px justify-evenly w-80`]} aria-label="Pagination">
        <button aria-label='previous results' onClick={onPrev} css={[tw`py-2 rounded-l-md border border-blue-300 bg-white text-sm font-medium text-blue-300 hover:bg-lightGray flex-1`]}>
          <span css={[tw`sr-only`]}>Previous</span>
          <svg css={[tw`h-5 w-5 mx-auto`]} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fillRule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" />
          </svg>
        </button>
        {pages?.map((value, index) => {
          if ((value < currentPage + 3 && value > currentPage - 3) || (currentPage < 3 && value < 6) || (currentPage > pages.length - 3 && value > pages.length - 5 )) {
            return <button key={index} onClick={() => onGotoPage(index + 1)} css={[tw`bg-white border-blue-300 text-blue-300 hover:bg-lightGray relative text-center items-center py-2 border text-sm font-medium flex-1`, currentPage === index + 1 && tw`border-blue-250 text-blue-250  bg-lightGray`]}>{value.toString()}</button>
          }
            
        })}
        <button aria-label='Next results' onClick={onNext} css={[tw`py-2 rounded-r-md border border-blue-300 bg-white text-sm font-medium text-blue-300 hover:bg-lightGray flex-1`]}>
          <span css={[tw`sr-only`]}>Next</span>
          <svg css={[tw`h-5 w-5 mx-auto`]} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        </button>
      </nav>
  </div>
</div>
  )
}

export default Pagination