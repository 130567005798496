import React, { useState } from 'react';
import { SearchResultProps } from './Search.d';
import Pagination from './Pagination';
const { getSlugFromUrl } = require('../../helpers')
import SearchResult from './SearchResult';
import tw from 'twin.macro';

interface Props {
  results: SearchResultProps[]
  resultsPerPage?: number
}

const PaginatedResults: React.FC<Props> = ({results, resultsPerPage = 10}) => {

  const [currentPage, setCurrentPage] = useState(1)

  const indexOfLastResult = currentPage * resultsPerPage
  const indexOfFirstResult = indexOfLastResult - resultsPerPage
  const currentResults = results?.slice(indexOfFirstResult, indexOfLastResult)

  const onNextHandler = () => {
    if(currentPage * resultsPerPage < results.length) {
      setCurrentPage((prev) => prev +1)
    }
  }

  const onPrevHandler = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1)
    }
  }
  
  const onGotoPageHandler = (page: number) => {
    setCurrentPage(page)
  }

  return (
    <>
      <Pagination onGotoPage={onGotoPageHandler} onNext={onNextHandler} onPrev={onPrevHandler} total={results.length} itemsPerPage={resultsPerPage} currentPage={currentPage}  />
      <div css={[tw`mt-2`]}>
        {currentResults?.map((item, index) => (
          <SearchResult
            css={[tw`p-2 -ml-2 outline-none text-blue-250 hover:text-blue-300 active:underline font-extrabold text-lg focus:border focus:border-dotted focus:border-blue-250 focus:ml-[-9px] focus:mt-[-1px] `]}
            key={index}
            {...item}
            url={getSlugFromUrl(item.url)}
          />
        ))}
      </div>
      <Pagination onGotoPage={onGotoPageHandler} onNext={onNextHandler} onPrev={onPrevHandler} total={results.length} itemsPerPage={resultsPerPage} currentPage={currentPage}  />
    </>
  )
}

export default PaginatedResults